import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'field',
    'map',
    'street',
    'city',
    'province',
    'postal_code',
    'country',
    'latitude',
    'longitude',
  ]

  connect() {
    // Turbo Sucks, so if google is already available, just init the map
    if (typeof(google) != 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    const latitude = parseFloat(this.data.get('latitude')) || 39.5
    const longitude = parseFloat(this.data.get('longitude')) || -98.35

    // We assume if this value isn't available, it's new new form as opposed to edit
    const is_new_form = this.data.get('latitude') == null

    const options = {
      types: ['address'],
      componentRestrictions: {country: 'ca'}
     };

    // Initialize the map
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: (is_new_form ? 4 : 15)
    })

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })

    if (!is_new_form) {
      this.marker.setPosition({lat: latitude, lng: longitude})
      this.marker.setVisible(true)
    }

    // Initialize the autocomplete
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, options)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace()

    if (!this.place.geometry) {
      window.alert(`No details available for input: ${this.place.name}`)
      return
    }

    // Update the Map
    if (this.place.geometry.viewport) {
      this.map.fitBounds(this.place.geometry.viewport)
    } else {
      this.map.setCenter(this.place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(this.place.geometry.location)
    this.marker.setVisible(true)


    // Fill in as many of the address fields as possible
    var street_number = find('street_number');

    // Sometimes Google doesn't return street_number field, but address is correct in the auto-completer
    // Use the value from autocomplete to get the street address
    if (!street_number || street_number === '') {
      street_number = this.fieldTarget.value.split(',')[0];
      this.streetTarget.value = street_number
    } else {
      // has street number, append route for full street address
      street_number += ' ' + find('route');
      this.streetTarget.value = street_number
    }

    this.cityTarget.value = this.findType('locality', 'long_name')
    this.provinceTarget.value = this.findType('administrative_area_level_1')
    this.countryTarget.value = this.findType('country', 'long_name')
    this.postal_codeTarget.value = this.findType('postal_code')

    this.latitudeTarget.value = this.place.geometry.location.lat()
    this.longitudeTarget.value = this.place.geometry.location.lng()
  }

  findType(type, name) {
    if (typeof name === 'undefined') {
      name = 'short_name';
    }

    var component = this.place.address_components.filter(function(obj) {
      return $.inArray(type, obj['types']) !== -1;
    });

    if (component.length > 0) {
      return component[0][name];
    }

    return '';
  };

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }
}
