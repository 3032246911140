// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "./jquery"
import LocalTime from "local-time"
import EasyAutocomplete from "easy-autocomplete"


// Start localtime helper
LocalTime.start()

// Broadcast Goolge Maps to all Stimulus controllers
window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}


document.addEventListener("turbo:load", () => {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})
