import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "field",
    "make",
    "model",
    "kind",
    "action",
    "bore",
    "bore_kind",
    "barrel_length",
    "barrel_length_unit",
    "magazine_capacity",
    "classification"
  ]

  connect() {
    let $autocomplete = $("#firearm-autocomplete")
    let _this = this

    var options = {
      url: function(phrase) {
        return "/firearms.json?q=" + phrase
      },
      getValue: function(element) {
        return `${element.make} ${element.model} ${element.bore}, Barrel: ${element.barrel_length} ${element.barrel_length_unit}, Magazine Capacity: ${element.magazine_capacity}`
      },
      requestDelay: 350,
      list: {
        maxNumberOfElements: 15,
        onChooseEvent: function() {
            var selectedItemData = $autocomplete.getSelectedItemData()
            _this.assignFormValues(selectedItemData)
        },
        match: {
          enabled: false
        }
      }
    };

    $autocomplete.easyAutocomplete(options);
  }

  assignFormValues(data) {
    console.log(data);
    this.makeTarget.value = data.make
    this.modelTarget.value = data.model
    this.kindTarget.value = data.kind
    this.boreTarget.value = data.bore
    this.bore_kindTarget.value = data.bore_kind
    this.barrel_lengthTarget.value = data.barrel_length
    this.barrel_length_unitTarget.value = data.barrel_length_unit
    this.magazine_capacityTarget.value = data.magazine_capacity
    this.actionTarget.value = data.action
    this.classificationTarget.value = data.classification
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }
}
